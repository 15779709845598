<template>
  <div class="profile">

    <div class="profile-image">
      <img :src="profileImg" :alt="profileName">
    </div>

    <div class="profile-content">

      <div class="profile-name">
        <h1>{{ profileName }}</h1>
      </div>

      <div class="profile-position">
        <h2>{{ profilePosition }}</h2>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'BrokerProfile',
  data: function() {
    return {
      // profileName: "Peter Matthews",
      // profilePosition: "Director of Sales and Marketing",
      // profileImg: require("@/assets/peter-matthews.png"),
      // profileName: "Aysan Pamir Ozel",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/aysan-ozel.jpg"),
      // profileName: "Pedja Savovic Jovanovic",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/pedja-savovic.jpg"),
      // profileName: "Laurent Chocron",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/laurent-chocron.jpg"),
      // profileName: "Jack Hodgkin",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/jack-hodgkin.jpg"),
      // profileName: "Sam Shooter",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/sam-shooter.jpg"),
      profileName: "John Accardo",
      profilePosition: "Business Development",
      profileImg: require("@/assets/john-accardo.jpg"),
      // profileName: "Nancy Dunn",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/nancy-dunn.jpg"),
      // profileName: "Joshua Sokoya",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/joshua-sokoya.jpg"),
      // profileName: "Lyndon Miles",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/lyndon-miles.jpg"),
      // profileName: "Dean Saunders",
      // profilePosition: "Business Development",
      // profileImg: require("@/assets/dean-saunders.jpg"),
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .profile {
    display: flex;
    align-items: center;
    padding: 1rem;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
  }
  .profile-image {
    max-width: 140px;
    max-height: 140px;
    border-radius: 50%;
    overflow: hidden;
    background: #fff;
    border: solid 5px #fff;
  }
  .profile-image img {
    width: 100%;
    height: 100%;
  }
  .profile-content {
    margin: 0 1rem;
    color: #fff;
  }
  .profile-name h1 {
    font-size: 7vw;
    line-height: 1em;
  }
  .profile-position h2 {
    font-size: 4vw;
    font-weight: 400;
  }
  /* For small mobiles */
  @media only screen and (max-width: 599px) {
    .profile-image {
      max-width: 90px;
      max-height: 90px;
      border: solid 3px #fff;
    }
  }
  /* For desktop */
  @media only screen and (min-width: 768px) {
    .profile {
      padding: 2rem;
    }
    .profile-content {
      margin: 0 2rem;
    }
    .profile-name h1 {
      font-size: 3rem;
    }
    .profile-position h2 {
      font-size: 1.5rem;
    }
  }
</style>